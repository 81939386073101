import {
  Box,
  Button,
  Chip,
  fade,
  Typography,
  useTheme,
} from "@material-ui/core"
import listingPath from "../util/listingPath"
import styled from "styled-components"
import formatPrice from "../util/formatPrice"
import { ReactComponent as Beds } from "../icons/beds.svg"
import { ReactComponent as Area } from "../icons/area.svg"
import { ReactComponent as Bath } from "../icons/bath.svg"
import PlaceHolder from "./Placeholder"
import { isNil } from "lodash"

const Details = styled(Box)`
  ${({ theme }) => `
    background-color:${fade(theme.palette.primary.dark, 0.8)};
    transition: 0.3s all;
    opacity:0;
    `}
`
const DetailsPreview = styled(Details)`
  opacity: 1;
`

const Wrapper = styled(Box)`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    ${Details} {
      opacity: 1;
    }
    ${DetailsPreview} {
      opacity: 0;
    }
  }
`

const FeatureIcon = styled(Box)`
  svg {
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }
`

export const Feature = ({ Icon, label }) => (
  <FeatureIcon display="flex" alignItems="center" justifyContent="center">
    {Icon && (
      <Box mr={0.5} component="span">
        <Icon />
      </Box>
    )}
    <Typography gutterBottom>{label}</Typography>
  </FeatureIcon>
)

const ListingPreview = ({
  title,
  price,
  soldPrice,
  status,
  type,
  image,
  images,
  bedrooms,
  bathrooms,
  squareFeet,
  location,
  ...listing
}) => {
  const theme = useTheme()
  const Title = () => (
    <Typography style={{ fontWeight: `bold`, textTransform: `uppercase` }}>
      {title}
    </Typography>
  )
  const Price = () =>
    price ? (
      <Typography style={{ alignSelf: `center` }}>
        {formatPrice(price)}
      </Typography>
    ) : null
  return (
    <Typography
      component={title ? `a` : `div`}
      href={
        title
          ? listingPath({
              type,
              title,
              location:
                typeof location === `string` ? { name: location } : location,
              bedrooms,
            })
          : `#`
      }
      color="inherit"
      style={{ "&, &:hover": { borderColor: `white !important` } }}
    >
      <Wrapper
        overflow="hidden"
        height={600}
        display="flex"
        alignItems="center"
        justifyContent="center"
        css={{
          backgroundImage: image ? `url(${image.small.src})` : ``,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center center`,
          boxShadow: `0px 0px ${theme.spacing(1)} ${fade(
            theme.palette.primary.main,
            0.2
          )}`,
        }}
        position="relative"
      >
        {isNil(image) && <PlaceHolder />}
        {(title || price) && (
          <>
            <DetailsPreview
              overflow="hidden"
              position="absolute"
              bottom={0}
              left={0}
              width={1}
              display="flex"
              p={1}
              flexDirection="column"
              alignItems="center"
              color="white"
            >
              {soldPrice && (
                <Chip
                  color="primary"
                  label={`Sold: ${formatPrice(soldPrice)}`}
                />
              )}
              {status && status !== `For Sale` && !soldPrice && (
                <Chip color="primary" label={status} />
              )}
              {title && <Title />}
              {price && <Price />}
            </DetailsPreview>
            <Details
              height={1}
              overflow="hidden"
              position="absolute"
              left={0}
              width={1}
              display="flex"
              px={1}
              py={2}
              flexDirection="column"
              textAlign="center"
              color="white"
            >
              <Box mb={2}>
                <Box width="80px" margin="auto" pb={1}>
                  <img src="/images/rr.svg" alt="" />
                </Box>
                <Title />
                <Price />
              </Box>
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                {bedrooms && <Feature Icon={Beds} label={`${bedrooms} Beds`} />}
                {bathrooms && (
                  <Feature Icon={Bath} label={`${bathrooms} Baths`} />
                )}
                {squareFeet && (
                  <Feature Icon={Area} label={`${squareFeet} Sq. Feet`} />
                )}
              </Box>
              <Box>
                <Button color="inherit">See more</Button>
              </Box>
            </Details>
          </>
        )}
      </Wrapper>
    </Typography>
  )
}

export default ListingPreview
