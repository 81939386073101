const slugify = require(`slugify`)

module.exports = ({ bedrooms, location, title }) => {
  return `/${slugify(
    `${bedrooms}-bed-property-${location.name}-county-dublin-${title}`,
    {
      lower: true,
    }
  )}`
}
