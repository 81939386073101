export default (price) => {
  let suffix = ``
  let label = price
  if (price >= 10000) {
    suffix = `K`
    label = price / 1000
  }
  if (price >= 1000000) {
    label = price / 1000000
    suffix = `M`
  }
  return `€${label}${suffix}`
}
