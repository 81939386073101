import { Box, Typography, useTheme } from "@material-ui/core"

const PlaceHolder = (props) => {
  const theme = useTheme()
  return (
    <Box
      color="white"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      css={{
        background: `linear-gradient(to bottom, ${theme.palette.primary.dark} 0%,${theme.palette.primary.main} 100%);`,
      }}
      {...props}
    >
      <img src="/images/rr.svg" width="100px" height="100px" alt="RR" />
      <Typography variant="h2" color="inherit">
        Coming Soon
      </Typography>
    </Box>
  )
}

export default PlaceHolder
